import React from "react";
import { Box} from "@mui/material";
import ChatbotGuide from "../components/ChatbotGuide";


const ChatbotGuideView=()=>{
    return(
        <Box >
        <ChatbotGuide/> 
        </Box>
    )
}

export default ChatbotGuideView;