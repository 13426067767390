// constants.js
export const PRICING_TIERS = [
    {
      title: "Classic",
      id:1,
      monthly: "$20/month",
      features: [
        "Real-Time Audio/Video Transcription (25 hours/month)",
        "Document/Text Translation (500 documents/month)",
        "Text-to-Speech Transformation (20 hours/month)",
        "Real-Time Speech-to-Speech Translation (20 hours/month)",
        "Text/Document Summarization (300 documents/month)",
        "Use-Case Solutions and Customization (10 hours/month)"
      ]
    },
    {
      title: "Classic Pro",
      id:2,
      monthly: "$50/month",
      features: [
        "Real-Time Audio/Video Transcription (50 hours/month)",
        "Document/Text Translation (1000 documents/month)",
        "Text-to-Speech Transformation (40 hours/month)",
        "Real-Time Speech-to-Speech Translation (40 hours/month)",
        "Text/Document Summarization (600 documents/month)",
        "Use-Case Solutions and Customization (20 hours/month)"
      ]
    },
    {
      title: "Enterprise Plus",
      id:4,
      monthly: "Custom pricing",
      features: [
        "Unlimited Real-Time Audio/Video Transcription",
        "Unlimited Document/Text Translation",
        "Unlimited Text-to-Speech Transformation",
        "Unlimited Real-Time Speech-to-Speech Translation",
        "Unlimited Text/Document Summarization",
        "Unlimited Use-Case Solutions and Customization",
        "Dedicated account manager",
        "Custom enterprise solutions"
      ]
    }
  ];
  