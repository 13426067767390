import React from "react";
import { Box} from "@mui/material";
import LanguageMatrix from "../components/LanguageMatrix";


const LanguageSupport=()=>{
    return(
        <Box >
        <LanguageMatrix/> 
        </Box>
    )
}

export default LanguageSupport;