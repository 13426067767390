import React from "react";
import { Box} from "@mui/material";
import ProfileComponent from "../components/ProfileComponent";


const ProfileView=()=>{
    return(
        <Box >
        <ProfileComponent/> 
        </Box>
    )
}

export default ProfileView;