import React from "react";
import { Box} from "@mui/material";
import LiveStreamComponent from "../components/AIAgentsDashboard";

const LiveStream =()=>{
    return(
        <Box>
            <LiveStreamComponent/>
        </Box>
    )
}

export default LiveStream;