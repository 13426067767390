import React from "react";
import { Box} from "@mui/material";
import VideoStreamComponent from '../components/VoiceAI'

const VideoStream =()=>{
    return(
        <Box>
            <VideoStreamComponent/>
        </Box>
    )
}

export default VideoStream;